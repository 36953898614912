<template>
  <div class="common-footer">
    <div class="footer-inner">
      <p>
        <NuxtLink to="https://nuxtjs.org">帮助</NuxtLink>
        ｜
        <NuxtLink to="https://nuxtjs.org">隐私</NuxtLink>
        ｜
        <NuxtLink to="https://nuxtjs.org">条款</NuxtLink>
      </p>
      <p>2021-2023 Copyrights reserved <a href="https://beian.miit.gov.cn/">蜀ICP备2021030067号</a></p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="less" scoped>
.common-footer {
  width: 100%;
  height: 230px;
  background: #333333;
  color: #ffffff;

  .footer-inner {
    @apply min-w-1430 max-w-1800 flex flex-col justify-center items-center text-w text-f14;
    height: 100%;
    margin: 0 auto;
    a {
      color: #ffffff;
    }
    p {
      @apply flex;
      margin-bottom: 16px;
    }
  }
}
</style>
